import axios from "@/utils/axios";

export const userDetail = () => axios.get(`/api/user/app/detail`);
export const certificateSelectList = () =>
  axios.get(`/api/course/certificate/selectList`);
export const selectMyCourseList = () =>
  axios.get(`/api/course/selectMyCourseList`);
export const selectMyLearningRecordList = () =>
  axios.get(`/api/course/selectMyLearningRecordList`);
export const selectMockExamList = (data: any) =>
  axios.get(`/api/exam/examination/selectMockExamList`, { params: data });
export const selectMyErrorQuestionList = (data: any) =>
  axios.get(`/api/exam/error/question/selectMyErrorQuestionList`, {
    params: data,
  });
export const selectMockExamRecordList = (data: any) =>
  axios.get(`/api/exam/mock/examination/record/selectMockExamRecordList`, {
    params: data,
  });
export const updateInfo = (data: any) =>
  axios.post(`/api/user/app/updateInfo`, data);
export const applyCertSendMailTeacher = (data: any) =>
  axios.post(`/api/user/mail/applyCertSendMailTeacher`, data);
